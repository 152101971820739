<template>
	<div class="ziliao">
		<div class="sitebox">
			<div class="chaxunjieguo">
				查询结果
			</div>
			<div class="searchbox">
				<div class="inuptbox">
					<input type="text" v-model="search" placeholder="请输入关键字搜索" />
				</div>
				<div class="searchbtn" @click="getlist">
					<img class="iconimg" src="pic/searchwhite.png" alt="">
				</div>
			</div>
		</div>
		<div class="boxlist">
			<div class="shouce" @click="toDownload(item)" v-for="item in list" :key="item.id">
				<img class="cover" :src="item.cover_url" alt="">
				<div class="name">{{ item.name }}</div>
			</div>
		</div>

		<div class="page">
			<el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="size" background
				layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>

	</div>
</template>

<script>
import { list } from '@/api/website/ziliao'
export default {
	name: 'PcWebsiteIntro',

	data() {
		return {
			sreach: '',
			list: [],
			page: 1,
			size: 15,
			total: 0,
		}
	},

	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	watch: {
		$route: {

			handler(v) {

				if (v.query.page > 1) {
					this.page = v.query.page
				} else {
					this.page = 1
				}
				this.getlist()
			}


		}
	},



	mounted() {
		if (this.$route.query.page > 1) {
			this.page = this.$route.query.page
		} else {
			this.page = 1
		}
		this.getlist()
	},

	methods: {
		toDownload(item) {
			window.open(item.file_url, 'top')
		},


		handleCurrentChange(val) {
			const tourl = '/' + this.site.path + '/list/ziliao?page=' + val
			this.$router.push(tourl)
		},

		// seachlist() {
		// 	list({ site_id: this.site.id, name: this.search }).then(res => {
		// 		this.list = res.data.list
		// 		this.total = res.data.total
		// 	})
		// },

		getlist() {
			let params = { site_id: this.site.id, page: this.page, size: this.size }
			if (this.search !== '') {
				params.name = this.search
			}

			list(params).then(res => {
				this.list = res.data.list
				this.total = res.data.total
			})
		}

	}

}

</script>


<style lang="scss" scoped>
.chaxunjieguo {
	padding-left: 20px;
	width: 64px;
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000000;
	line-height: 22px;
}

.ziliao {
	position: relative;
}

.boxlist {

	padding: 10px;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;

	.shouce {
		margin-bottom:30px;
		width: 236px;
		height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;

		.cover {
			width: 216px;
			height: 266px;
			border: 1px solid #C1C1C1;
		}

		.name {
			text-align: left;
			width: 216px;
			height: 20px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
		}

	}
}


.sitebox {
	height: 68px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;


	.searchbox {
		height: 28px;
		width: 218px;
		display: flex;
		margin-right: 20px;
		.inuptbox {
			height: 28px;
			width: 162px;

			input {
				outline: none;
				padding-left: 5px;
				height: 28px;
				box-sizing: border-box;
				width: 162px;
				border: none;
				background: #F2F2F2;
			}
		}

		.searchbtn {
			width: 56px;
			height: 28px;
			background: #00A3E4;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			.iconimg {
				width: 16px;
				height: 16px;
			}
		}
	}
}

.ziliao {
	width: 1200px;
	height: 1114px;
	background: #FFFFFF;


}

.page {
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;

}
</style>