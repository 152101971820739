import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//首页数据
export function ziliaolist(params) {
	return loginRequest({
    url: '/frontend/website/promotions-files/home-list',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//列表数据
export function list(params) {
	return loginRequest({
    url: '/frontend/website/promotions-files/list',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//详情数据
export function detail(params) {
	return loginRequest({
    url: '/frontend/website/headlines/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}



//列表页热门列表
export function hotlist(params) {
	return loginRequest({
    url: '/frontend/website/headlines/hot',
		method: 'post',
		data:Qs.stringify(params)
	})
}



